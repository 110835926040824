
import { defineStore } from 'pinia'
import mushroom from "cem-primary-api";

export const profileUserStore = defineStore({
    id: 'profileUser',
    state: () => ({
        name: "",
        avatarLink: "",
        roles: "",
        phone: "",
        email: "",
        department: "",
        group: []
    }),
    actions: {
        async getProfileUser() {
            const responseUser = await mushroom.$auth.meAsync();

            let id_currentUser = responseUser.result.id;
            try {
                const response = await mushroom.profile.listAsync({
                    filters: ["id=" + id_currentUser],
                });
                this.name = response.result[0].fullname
                this.roles = responseUser.result.roles[0];
                this.phone = response.result[0].phoneNumber ? response.result[0].phoneNumber : '';
                this.email = response.result[0].email ? response.result[0].email : '';
                if (response.result[0].avatarId) {
                    this.avatarLink = mushroom.$file.linkBuilder.thumb
                        .id(response.result[0].avatarId)
                        .build();
                }

                if (response.result[0].department_id) {
                    try {
                        const res = await mushroom.department.findByIdAsync({
                            id: response.result[0].department_id,
                        });
                        if (res.result) this.department = res.result.name
                    }
                    catch (e) {
                        console.error("Có lỗi: %o", e);
                    }
                }
                let filtersUserGroup = ""
                if (responseUser.result.roles.includes("Admin") || responseUser.result.roles.includes("AdminLocal")) {
                    filtersUserGroup = ["user_id=" + id_currentUser]
                } else filtersUserGroup = ""
                const responseDepartment = await mushroom.user_group.listAsync({
                    filters: filtersUserGroup,
                });
                this.group = [];
                responseDepartment.result?.forEach(async item => {
                    const response = await mushroom.group.findByIdAsync({
                        id: item.group_id // required
                    });
                    if (response.result) this.group.push(response.result.name)
                })
            } catch (e) {
                console.log("Có lỗi: %o", e);
            }
        },
    },
    getters: {
        getRoles: (state) => {
            return state
        }
    }
})

