
import { defineStore } from 'pinia'
export const actionDashboard = defineStore({
    id: 'actionDashboard',
    state: () => ({
        renderMap: false
    }),
    actions: {
        actionRenderMapComponent() {
            this.renderMap = !this.renderMap
        },
    },
    getters: {
        isRenderMap() {
            return this.renderMap
        }
    }
})

