<script setup>
import i18n from "../i18n";
import { onMounted, ref } from "vue";
//import { storeToRefs } from "pinia";
import mushroom from "cem-primary-api";
import { useRoute } from "vue-router";
import PageHeader from "../components/page-header.vue";
import Swal from "sweetalert2";
import { profileUserStore } from "../stores/profileUser";
const profileUser = profileUserStore();
const { getProfileUser } = profileUserStore();
import { langWeb } from "../stores/lang";
const lang_web = langWeb();
import LanguaService from "@/service/LanguageService";
const lang = ref(lang_web.showLang.toLocaleUpperCase());
import { actionDashboard } from "../stores/actionDashboard";
const storeActionDashboard = actionDashboard();
const route = useRoute();
const languages = [
  {
    flag: require("@/assets/images/flags/vietnam.svg"),
    language: "vi",
    title: "Việt Nam",
  },
  {
    flag: require("@/assets/images/flags/us.svg"),
    language: "en",
    title: "English",
  },
];
let lan = ref(i18n.locale);
let text = ref("");
let flag = ref("");

// function
const logout = async () => {
  Swal.fire({
    title: LanguaService[lang.value].t_warning,
    text: LanguaService[lang.value].t_logout_system,
    icon: "question",
    confirmButtonColor: "#f46a6a",
    cancelButtonText: LanguaService[lang.value].t_back,
    confirmButtonText: LanguaService[lang.value].t_logout,

    showCancelButton: true,
    allowOutsideClick: false,
  }).then(async (result) => {
    if (result.isConfirmed) {
      // let listDomain = [];
      // const valueDomain = localStorage.getItem("domainProbe");
      // if(valueDomain) {
      //   listDomain = valueDomain.split(",");
      //   listDomain.forEach(item => {
      //     window.open(item + '/clear.html');
      //   })
      // }
      const logoutMode = "invalidServerSession";
      window.localStorage.clear();
      try {
        await mushroom.$auth.logoutAsync({ mode: logoutMode });
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
      let urlLogin = location.origin + "/login";
      window.location.replace(urlLogin);
    }
  });
};
const toggleHamburgerMenu = () => {
  storeActionDashboard.actionRenderMapComponent();
  var windowSize = document.documentElement.clientWidth;

  if (windowSize > 767)
    document.querySelector(".hamburger-icon").classList.toggle("open");

  //For collapse horizontal menu
  if (document.documentElement.getAttribute("data-layout") === "horizontal") {
    document.body.classList.contains("menu")
      ? document.body.classList.remove("menu")
      : document.body.classList.add("menu");
  }

  //For collapse vertical menu
  if (document.documentElement.getAttribute("data-layout") === "vertical") {
    if (windowSize > 1024) {
      document.body.classList.remove("vertical-sidebar-enable");
      document.documentElement.getAttribute("data-sidebar-size") == "lg"
        ? document.documentElement.setAttribute("data-sidebar-size", "sm")
        : document.documentElement.setAttribute("data-sidebar-size", "lg");
    } else if (windowSize <= 1024) {
      document.body.classList.add("vertical-sidebar-enable");
      document.documentElement.setAttribute("data-sidebar-size", "lg");
    }
  }
};

const initFullScreen = () => {
  document.body.classList.toggle("fullscreen-enable");
  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
};

const setLanguage = async (locale, country, flags) => {
  lan.value = locale;
  text.value = country;
  flag.value = flags;
  document.getElementById("header-lang-img").setAttribute("src", flags);
  i18n.global.locale = locale;
  if (locale == "vi") {
    await actionLanguageUser(locale);
    lang_web.changeLangVI();
  }
  if (locale == "en") {
    await actionLanguageUser(locale);
    lang_web.changeLangEN();
  }
};
// xem lại api user_config
const actionLanguageUser = async (locale) => {
  const checkLanguage = await mushroom.user_config.listAsync({
    fields: "id,key,value",
    filters: ["key=language"],
  });
  if (checkLanguage.result?.length === 0) {
    await mushroom.user_config.createAsync({
      key: "language",
      value: locale,
    });
  } else {
    await mushroom.user_config.partialUpdateAsync({
      id: checkLanguage.result[0].id,
      value: locale,
    });
  }
};
getProfileUser();
const renderUserRole = (role) => {
  let userRole = "";
  if (role === "Admin") userRole = "Super Admin";
  if (role === "AdminPrimary") userRole = "Admin";
  if (role === "Edit") userRole = "Edit";
  if (role === "User") userRole = "View";
  return userRole;
};
onMounted(() => {
  document.addEventListener("scroll", function () {
    var pageTopbar = document.getElementById("page-topbar");
    if (pageTopbar) {
      document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50
        ? pageTopbar.classList.add("topbar-shadow")
        : pageTopbar.classList.remove("topbar-shadow");
    }
  });
  if (document.getElementById("topnav-hamburger-icon"))
    document
      .getElementById("topnav-hamburger-icon")
      .addEventListener("click", toggleHamburgerMenu);
  //getProfileUser();
});
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="btn header-item vertical-menu-btn topnav-hamburger"
            id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <!-- <span></span>
              <span></span>
              <span></span> -->
              <img src="../assets/icon/menu.svg" alt="menu" />
            </span>
          </button>
          <PageHeader
            :items="route.meta.breadcrumb.vi"
            v-if="lang_web.showLang == 'vi'"
          />
          <PageHeader :items="route.meta.breadcrumb.en" v-else />
        </div>
        <div class="d-flex align-items-center">
          <!-- <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="bx bx-search fs-22"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown"
            >
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div> -->

          <div class="dropdown ms-1 topbar-head-dropdown header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                id="header-lang-img"
                src="@/assets/icon/vn.svg"
                alt="Header Language"
                height="24"
                class="rounded"
                loading="lazy"
                v-if="lang_web.showLang == 'vi'"
                title="Ngôn ngữ"
              />
              <img
                id="header-lang-img"
                src="@/assets/images/flags/us.svg"
                alt="Header Language"
                height="20"
                class="rounded"
                loading="lazy"
                v-if="lang_web.showLang == 'en'"
                title="Language"
              />
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a
                href="javascript:void(0);"
                v-for="(entry, i) in languages"
                :key="`Lang${i}`"
                :value="entry"
                @click="setLanguage(entry.language, entry.title, entry.flag)"
                :class="{ active: lan === entry.language }"
                class="dropdown-item notify-item language py-2"
                data-lang="en"
                title="English"
              >
                <img
                  :src="entry.flag"
                  alt="user-image"
                  class="me-2 rounded"
                  height="18"
                />
                <span class="align-middle">{{ entry.title }}</span>
              </a>
            </div>
          </div>

          <div class="header-item d-none d-sm-flex">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle full-screen"
              data-toggle="fullscreen"
              @click="initFullScreen"
            >
              <!-- <i class="bx bx-fullscreen fs-22"></i> -->
              <img src="../assets/icon/maximize.svg" alt="maximize" />
            </button>
          </div>

          <div class="dropdown header-item topbar-user">
            <button
              type="button"
              class="btn"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="d-flex align-items-center">
                <img
                  v-if="profileUser.avatarLink"
                  class="rounded-circle header-profile-user"
                  :src="profileUser.avatarLink"
                  alt="Header Avatar"
                />
                <img
                  v-else
                  class="rounded-circle header-profile-user"
                  src="../assets/icon/profile-circle.svg"
                  alt="Header Avatar"
                />
                <span class="text-start">
                  <span class="d-none d-xl-inline-block user-name-text">{{
                    profileUser.name
                  }}</span>
                  <span class="d-none d-xl-block user-name-sub-text">{{
                    renderUserRole(profileUser.roles)
                  }}</span>
                </span>
              </span>
            </button>

            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <h6 class="dropdown-header">
                {{ $t("t-wellcome") }}
                {{ profileUser.name }}
              </h6>
              <router-link class="dropdown-item" to="/profile"
                ><i
                  class="ri-account-circle-line text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">{{
                  $t("t-profile")
                }}</span></router-link
              >
              <router-link class="dropdown-item" to="/reset-password"
                ><i
                  class="ri-exchange-line text-muted fs-16 align-middle me-1"
                ></i>
                <span class="align-middle">{{
                  $t("t-change-password")
                }}</span></router-link
              >
              <a class="dropdown-item" @click="logout()" style="cursor: pointer"
                ><i
                  class="mdi mdi-logout fs-16 align-middle me-1"
                  style="color: #ff3636"
                ></i>
                <span
                  class="align-middle"
                  data-key="t-logout"
                  style="color: #ff3636"
                  >{{ $t("t-logout") }}</span
                ></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<style scoped>
.text-start {
  margin-left: 8px;
}
#page-header-user-dropdown {
  padding: 0;
}
.topbar-user,
.full-screen {
  margin-left: 24px;
}
.btn-ghost-secondary:hover,
.btn-ghost-secondary:focus,
.btn-ghost-secondary:active {
  background-color: transparent;
}
#topnav-hamburger-icon {
  width: fit-content;
  padding: 0;
  margin-right: 24px;
}
.user-name-sub-text {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
</style>
