<script>
import router from "@/router";
import { SimpleBar } from "simplebar-vue3";
import { layoutComputed } from "@/state/helpers";
import mushroom from "cem-primary-api";

import NavBar from "@/components/nav-bar";
import Menu from "@/components/menu.vue";
// ẩn toàn bộ right bar tùy chọn giao diện dashboard
import RightBar from "@/components/right-bar";
//import Footer from "@/components/footer";
localStorage.setItem("hoverd", false);

/**
 * Vertical layout
 */
export default {
  components: { NavBar, RightBar, SimpleBar, Menu },
  data() {
    return {
      isMenuCondensed: false,
      urlLogo: "",
    };
  },
  computed: {
    ...layoutComputed,
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
  },
  methods: {
    initActiveMenu() {
      if (
        document.documentElement.getAttribute("data-sidebar-size") ===
        "sm-hover"
      ) {
        localStorage.setItem("hoverd", true);
        document.documentElement.setAttribute(
          "data-sidebar-size",
          "sm-hover-active"
        );
      } else if (
        document.documentElement.getAttribute("data-sidebar-size") ===
        "sm-hover-active"
      ) {
        localStorage.setItem("hoverd", false);
        document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
      } else {
        document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
      }
    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    async get_logo() {
      try {
        const response = await mushroom.system_config.listAsync({
          fields: "id,key,value,is_public,note",
          filters: ["key=logo"],
        });
        if (response.result?.length > 0) {
          this.urlLogo = mushroom.$file.linkBuilder.thumb
            .id(response.result[0].value)
            .build();
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
  },
  mounted() {
    this.get_logo();
    if (localStorage.getItem("hoverd") == "true") {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        "sm-hover-active"
      );
    }
    document.getElementById("overlay").addEventListener("click", () => {
      document.body.classList.remove("vertical-sidebar-enable");
      document.querySelector(".hamburger-icon").classList.add("open");
    });
  },
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar />
    <div>
      <!-- ========== Left Sidebar Start ========== -->
      <!-- ========== App Menu ========== -->
      <div class="app-menu navbar-menu">
        <div class="navbar-brand-box" v-if="this.urlLogo">
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img :src="urlLogo" alt="logo" height="30" />
            </span>
            <span class="logo-lg">
              <img :src="urlLogo" alt="logo" height="60" />
            </span>
          </router-link>
        </div>
        <div v-else class="navbar-brand-box">
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="../assets/images/logo-cem.png" alt="logo" height="30" />
            </span>
            <span class="logo-lg">
              <img src="../assets/images/logo-cem.png" alt="logo" height="60" />
            </span>
          </router-link>
        </div>
        <SimpleBar id="scrollbar" class="h-100" ref="scrollbar">
          <Menu></Menu>
        </SimpleBar>
      </div>
      <!-- Left Sidebar End -->
      <!-- Vertical Overlay-->
      <div class="vertical-overlay" id="overlay"></div>
    </div>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content">
      <div class="page-content">
        <!-- Start Content-->
        <div class="container-fluid" style="padding: 0px 0px">
          <slot />
        </div>
      </div>
    </div>
    <RightBar />
  </div>
</template>
